// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
//$body-bg: #000;
// COLORS //
$body-color: black;
$primary: #e3324b;
$secondary: #f9a81b;

// BREAKPOINTS //
$grid-breakpoints: (
  xs: 0,
  sm: 599px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// TYPOGRAPHY //
$headings-color: #e3324b;
// $headings-font-family: 'Termina', sans-serif;
$font-family-base: 'Poppins', sans-serif;
// $font-size-base: 1.5rem;

// A LINK //
$link-decoration: none;
$link-hover-color: $primary;
$link-hover-decoration: underline;

// $container-padding-x: 3rem;

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

// 6. Add additional custom code here

// styles 144kb
// styles brez tega 72kb

.display-1, .display-2 {
 // font-family: 'Termina', sans-serif;
}

h1 {
  display: inline-block;
  margin: 1rem 0;
}

p {
  margin: 1em 0;
}
li {
  //@include font-size(1.3rem);
}
